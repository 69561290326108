import React from 'react'
import type { Nilable } from 'tsdef'
import Axios from 'axios'

import { MINUTE, combineClasses, formatTime } from '~/util'
import { ItemName } from '../titles'
import { parseDoorCode } from '~/util/text'
import { Tooltip, TooltipProps } from '../popovers'

import { LockAction } from '../buttons'
import { LOCK_DEVICE_ACCESS_TYPE } from '~/service'

import LockIcon from '~/assets/icons/lock.svg?react'

import styles from './DoorCode.module.scss'

type LockTooltipProps = Omit<TooltipProps, 'content'> & LockCodeProps

export const LockTooltip = React.memo(
  ({
    code,
    startTime,
    endTime,
    currentTime = new Date(),
    disabled,
    appearsInModal,
    ...rest
  }: LockTooltipProps) => {
    const { code: pin, char, position } = parseDoorCode(code)

    const action = !char
      ? pin
      : position === 'start'
      ? `${char} then ${pin}`
      : `${pin} then ${char}`

    const tooltipContent = !disabled
      ? `Type ${action} into the lock keypad.`
      : startTime && currentTime < new Date(startTime)
      ? `This lock is not available until ${formatTime(startTime)}.`
      : endTime && currentTime > new Date(endTime)
      ? `This lock has expired.`
      : `This lock is not active at the moment.`

    return (
      <Tooltip
        layerOptions={{ placement: 'bottom-center' }}
        display={disabled ? 'error' : 'info'}
        {...rest}
        content={tooltipContent}
        appearsInModal={appearsInModal}
      />
    )
  }
)

interface LockCodeProps extends React.HTMLAttributes<HTMLDivElement> {
  startTime?: Nilable<string>
  endTime?: Nilable<string>
  currentTime?: Date
  code: string
  disabled?: boolean
  appearsInModal?: boolean
}

export const LockCode = React.memo(
  ({
    code,
    startTime,
    endTime,
    currentTime = new Date(),
    disabled,
    appearsInModal,
    ...rest
  }: LockCodeProps) => {
    return (
      <LockTooltip
        startTime={startTime}
        endTime={endTime}
        currentTime={currentTime}
        code={code}
        disabled={disabled}
        appearsInModal={appearsInModal}
      >
        <div
          data-testid="LockCode"
          className={combineClasses(
            styles.LockCode,
            // Make sure the trigger is clickable within the card.
            styles.lockActionTooltipTrigger,
            disabled && styles.disabled
          )}
          onClick={(e) => e.stopPropagation()}
          {...rest}
        >
          <LockIcon className={styles.lockIcon} />
          <ItemName data-testid="code" className={styles.code} as="span">
            {code}
          </ItemName>
        </div>
      </LockTooltip>
    )
  }
)

interface CodeOrActionProps {
  startTime: Nilable<string>
  endTime: Nilable<string>
  currentTime?: Date
  canAccess: boolean
  onOpen: (e: React.MouseEvent) => void
  label: Nilable<string>
  accessType: Nilable<LOCK_DEVICE_ACCESS_TYPE>
  /**
   * The door code or API URL to open the lock (deepending on the `accessType`).
   */
  access: Nilable<string>
  /**
   * Whether the door code is being displayed in a modal. This ensures that the
   * lock tooltip is shown above the modal overlay.
   */
  appearsInModal?: boolean
}

/**
 * Show either a lock pin code or a button depending on the `accessType`. If the
 * access type is `IFRAME` or `API`, a button is rendered and the `onOpen` prop
 * is required. If the access type is `WEB`, a link is rendered which opens
 * in a new window.
 */
export const CodeOrAction = React.memo(
  ({
    canAccess,
    startTime,
    endTime,
    currentTime = new Date(),
    onOpen,
    label,
    accessType,
    access,
    appearsInModal,
  }: CodeOrActionProps) => {
    if (!access) return null

    const maybeWrapWithTooltip = (c: React.ReactNode) =>
      canAccess ? (
        c
      ) : (
        <LockTooltip
          startTime={startTime}
          endTime={endTime}
          currentTime={currentTime}
          code={access}
          disabled={!canAccess}
          appearsInModal={appearsInModal}
        >
          <div
            // This extran wrapping div ensures that the tooltip pointer events
            // work even though the button is disabled.
            className={styles.lockActionTooltipTrigger}
          >
            {c}
          </div>
        </LockTooltip>
      )

    switch (accessType) {
      case 'WEB':
      case 'API':
        return maybeWrapWithTooltip(
          <LockAction
            data-testid="DoorCode.unlockButton"
            button={accessType !== 'WEB'}
            href={accessType === 'WEB' ? access : undefined}
            blank={accessType === 'WEB' ? true : undefined}
            onClick={accessType !== 'WEB' ? onOpen : undefined}
            disabled={!canAccess}
          >
            {label || 'Unlock'}
          </LockAction>
        )
      case 'PIN':
        return (
          <LockCode
            code={access}
            startTime={startTime}
            endTime={endTime}
            disabled={!canAccess}
            appearsInModal={appearsInModal}
          />
        )
      default:
        return null
    }
  }
)

interface DoorCodeProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onLockError' | 'id'> {
  label: Nilable<string>
  accessType: Nilable<LOCK_DEVICE_ACCESS_TYPE>
  /**
   * The door code or API URL to open the lock (deepending on the `accessType`).
   */
  access: Nilable<string>
  startTime: Nilable<string>
  endTime: Nilable<string>
  /**
   * Called when a remote lock fails to open.
   */
  onLockError: (name: string, error: unknown) => void
  /**
   * Allows you to mock the time that the door code thinks it is (ie. Date.now()).
   */
  currentTime?: Date
  /**
   * Whether to hide the door code when it has expired or the access time has
   * not started yet. This is used to hide the lock code on unti detail pages
   * since showing a disabled button in that context is less useful.
   */
  hideWhenExpired?: boolean
  /**
   * Whether the door code is being displayed in a modal. This ensures that the
   * lock tooltip is shown above the modal overlay.
   */
  appearsInModal?: boolean
}

/**
 * `<DoorCode>` displays either a door code or a button to open a lock. If lock
 * access is not available currently, then the code/button are disabled (or
 * hidden if the `hideWhenExpired` prop is true.
 *
 * If you need the door code to automatically update its enabled/disabled state,
 * use the `<DoorCodeTimer>` component instead.
 */
export const DoorCode = React.memo(
  ({
    label,
    accessType,
    access,
    startTime,
    endTime,
    currentTime = new Date(),
    onLockError,
    hideWhenExpired,
    appearsInModal,
    className,
    ...rest
  }: DoorCodeProps) => {
    const start = React.useMemo(
      () => (startTime ? new Date(startTime) : currentTime),
      [currentTime, startTime]
    )
    const end = React.useMemo(
      () => (endTime ? new Date(endTime) : undefined),
      [endTime]
    )

    const started = currentTime >= start
    const ended = end ? currentTime >= end : false
    const canAccess = started && !ended
    const showButton = access && accessType !== 'PIN'

    const openLock = React.useCallback(async () => {
      try {
        if (accessType === 'API') {
          await Axios.post(access!, { method: 'POST' })
        }
      } catch (e) {
        onLockError(label ?? '', e)
      }
    }, [accessType, access, onLockError, label])

    if (hideWhenExpired && (ended || !started)) {
      return null
    } else {
      return (
        <div
          data-testid="DoorCode"
          className={combineClasses(
            styles.DoorCode,
            className,
            canAccess && styles.accessible,
            ended && styles.expired,
            showButton && styles.urlLock
          )}
          {...rest}
        >
          <span data-testid="DoorCode.code" className={styles.codeValue}>
            <CodeOrAction
              startTime={startTime}
              endTime={endTime}
              currentTime={currentTime}
              canAccess={canAccess}
              onOpen={openLock}
              accessType={accessType}
              access={access}
              label={label}
              appearsInModal={appearsInModal}
            />
          </span>
        </div>
      )
    }
  }
)

/**
 * Renders `<DoorCode>` while keeping it in sync with the current time.
 */
export const DoorCodeTimer = React.memo((props: DoorCodeProps) => {
  const [currentTime, setCurrentTime] = React.useState(new Date())

  React.useEffect(() => {
    const timeout = setInterval(() => {
      setCurrentTime(new Date())
    }, MINUTE)
    return () => clearInterval(timeout)
  }, [])

  return <DoorCode currentTime={currentTime} {...props} />
})
