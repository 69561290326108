import React from 'react'
import { Nilable } from 'tsdef'

import { Amenity, Lock, Unit } from '~/service'
import { ItemName, SubTitle } from '../../titles'
import { DoorCodeTimer } from '../DoorCode'

import styles from './LockList.module.scss'
import { useId } from '~/util'

interface LockRowProps {
  label: string
  lock: Nilable<Lock>
  onLockError: (name: string, error: unknown) => void
}

export const ButtonRow = React.memo(
  ({ label, lock, onLockError }: LockRowProps) => (
    // We need to return the name and code directly in order for the CSS grid
    // styling to work.
    <li className={styles.ButtonRow}>
      <DoorCodeTimer
        label={label}
        access={lock?.access}
        accessType={lock?.accessType}
        startTime={lock?.startTime}
        endTime={lock?.endTime}
        onLockError={onLockError}
        className={styles.lockButton}
        appearsInModal={true}
      />
    </li>
  )
)

export const CodeRow = React.memo(
  ({ label, lock, onLockError }: LockRowProps) => {
    const id = useId('lock-code')
    return (
      <li className={styles.CodeRow}>
        <ItemName
          id={id}
          data-testid="LockList.CodeRow.lockName"
          className={styles.lockName}
          as="span"
        >
          {label}
        </ItemName>
        <DoorCodeTimer
          aria-labelledby={id}
          label={label}
          access={lock?.access}
          accessType={lock?.accessType}
          startTime={lock?.startTime}
          endTime={lock?.endTime}
          onLockError={onLockError}
          className={styles.lockCode}
          appearsInModal={true}
        />
      </li>
    )
  }
)

export const LockRow = React.memo(({ lock, ...rest }: LockRowProps) =>
  lock?.accessType === 'API' ? (
    <ButtonRow lock={lock} {...rest} />
  ) : (
    <CodeRow lock={lock} {...rest} />
  )
)

// This needs to be larger than the maximum floor number across all communities.
const MAX = 1000

/**
 * Get a sort score for an amenity. It makes a best guess to give main
 * entrances a higher score. After that, it will sort by lowest floor to
 * hightest floor (ie. first floor amenities get a higher score).
 *
 * @example
 * ```ts
 * const sorted = amenities.sort((a, b) => scoreAmenityLock(b) - scoreAmenityLock(a))
 * ````
 */
export function scoreAmenityLock(amenity: Amenity) {
  return amenity.specialType?.name === 'Leasing Office'
    ? MAX
    : amenity.name === 'Building Entrance' || amenity.name === 'Main Entrance'
    ? MAX - 1
    : amenity.floorPlate != null
    ? // Make sure these are sorted before locks with no floor plate.
      MAX - 2 - amenity.floorPlate.floor
    : 0
}

export interface LockListProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onLockError'> {
  amenities: Nilable<Amenity[]>
  units: Nilable<Unit[]>
  onLockError: (name: string, error: unknown) => void
}

/**
 * `<LockList>` displays all of a community's lock device access information.
 */
export const LockList = React.memo(
  ({ amenities: allAmenities, units, onLockError, ...rest }: LockListProps) => {
    const entranceListId = useId('entrance-locks')
    const amenityListId = useId('amenity-locks')
    const unitListId = useId('unit-locks')

    // Divide amenities into locks displayed in the tour steps and locks that
    // aren't shown in the steps but still need to be opened.
    const { amenities, entrances } = (
      allAmenities?.filter((a) => !!a?.lockDevice) || []
    ).reduce(
      (acc, amenity) => {
        if (
          amenity.floorPlate?.floor != null &&
          (!amenity.specialType ||
            amenity.specialType.name !== 'Leasing Office')
        )
          acc.amenities.push(amenity)
        else acc.entrances.push(amenity)
        return acc
      },
      {
        amenities: [] as Amenity[],
        entrances: [] as Amenity[],
      }
    )

    return (
      <div data-testid="LockList" {...rest}>
        {!!entrances.length && (
          <div className={styles.communityLocks}>
            <SubTitle id={entranceListId}>Entrance Locks</SubTitle>
            <ul
              className={styles.communityLockTable}
              aria-labelledby={entranceListId}
            >
              {entrances
                .sort((a, b) => scoreAmenityLock(b) - scoreAmenityLock(a))
                .map((amenity, index) => (
                  <LockRow
                    key={index}
                    label={amenity.name}
                    lock={amenity.lockDevice}
                    onLockError={onLockError}
                  />
                ))}
            </ul>
          </div>
        )}
        {!!amenities.length && (
          <div className={styles.communityLocks}>
            <SubTitle id={amenityListId}>Amenity Locks</SubTitle>
            <ul
              className={styles.communityLockTable}
              aria-labelledby={amenityListId}
            >
              {amenities
                .sort((a, b) => scoreAmenityLock(b) - scoreAmenityLock(a))
                .map((amenity, index) => (
                  <LockRow
                    key={index}
                    label={amenity.name}
                    lock={amenity.lockDevice}
                    onLockError={onLockError}
                  />
                ))}
            </ul>
          </div>
        )}
        {!!units?.length && (
          <div>
            <SubTitle id={unitListId}>Apartment Locks</SubTitle>
            <ol className={styles.doorLockTable} aria-labelledby={unitListId}>
              {units
                .filter((a) => !!a?.lockDevice)
                .map((unit, index) => (
                  <LockRow
                    key={index}
                    label={unit.unitNumber ?? ''}
                    lock={unit.lockDevice}
                    onLockError={onLockError}
                  />
                ))}
            </ol>
          </div>
        )}
      </div>
    )
  }
)
