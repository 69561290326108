import React from 'react'
import {
  Tooltip as TooltipBase_,
  // TooltipProps as TooltipBaseProps,
} from './TooltipBase'

import { combineClasses } from '~/util'

import styles from './Tooltip.module.scss'
import sharedStyles from '../shared.module.scss'

const TooltipBase = TooltipBase_ as any

export interface TooltipBaseProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'content'> {
  // This should come from HTMLDivElement and I'm not sure why it's not working.
  'data-testid'?: string
  content: React.ReactNode
  hoverOptions?: {
    delayEnter?: number
    delayLeave?: number
    hideOnScroll?: boolean
    onShow?: () => void
    onHide?: () => void
  }
  triggerProps?: React.HTMLAttributes<HTMLElement> & {
    // TODO This should be part of HTMLElement and not sure why it's not working
    // in this instance when it works in other places.
    'data-testid'?: string
  }
  /**
   * Whether the tooltip should be rendered into the modal content container.
   * This ensures that the tooltip is not rendered below the modal which is the
   * default behavior.
   */
  appearsInModal?: boolean
  layerOptions?: {
    triggerOffset?: number
    placement?:
      | 'top-left'
      | 'top-center'
      | 'top-right'
      | 'right-top'
      | 'right-center'
      | 'right-bottom'
      | 'bottom-right'
      | 'bottom-center'
      | 'bottom-left'
      | 'left-bottom'
      | 'left-center'
      | 'left-top'
  }
  onOpen?: () => void
  onClose?: () => void
  isOpen?: boolean
}

export interface TooltipProps extends TooltipBaseProps {
  display?: 'info' | 'success' | 'error' | 'warning'
}

/**
 * `<Tooltip>` takes a `content` prop and `children` prop to render a tooltip.
 *
 * The `content` prop is the text or JSX that will be displayed inside the
 * tooltip.
 *
 * The `children` prop can be an element or plain text that will
 * trigger the tooltip when hovered, clicked, or focused. If `children` is text,
 * it will be wrapped in a `span` element.
 *
 * You can change the tooltip's position via the `layerOptions` prop.
 *
 * This component is a wrapper around the `Tooltip` component from
 * `@thesoulfresh/react-tools` so you can pass any of those props.
 */
export const Tooltip = React.memo(
  ({
    'data-testid': dataTestId = 'Tooltip',
    triggerProps,
    display,
    className,
    layerOptions,
    appearsInModal,
    ...props
  }: TooltipProps) => (
    <TooltipBase
      data-testid="Tooltip.content"
      triggerProps={{
        'data-testid': 'Tooltip.trigger',
        ...triggerProps,
      }}
      className={combineClasses(
        sharedStyles.Popover,
        styles.Tooltip,
        display && sharedStyles[display],
        appearsInModal && sharedStyles.appearsInModal,
        className
      )}
      layerOptions={{
        triggerOffset: Number(sharedStyles.arrowSize) || 0,
        ...layerOptions,
      }}
      {...props}
    />
  )
)
